/*
 * @Descripttion:
 * @version:
 * @Author: chenyu
 * @Date: 2024-07-29 16:13:26
 * @LastEditors: chenyu
 * @LastEditTime: 2024-07-31 15:58:05
 */
/*
 * @Descripttion:
 * @version:
 * @Author: chenyu
 * @Date: 2024-06-22 15:50:19
 * @LastEditors: chenyu
 * @LastEditTime: 2024-06-29 11:26:30
 */
/*
 * @Descripttion:
 * @version:
 * @Author: chenyu
 * @Date: 2024-06-22 15:50:19
 * @LastEditors: chenyu
 * @LastEditTime: 2024-06-24 09:42:10
 */
import Vue from "vue";
import VueRouter from "vue-router";
import pubsub from "pubsub-js";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/main/index",
  },
  {
    path: "/main",
    name: "main",
    component: () => import("../views/index.vue"),
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("../views/home.vue"),
        meta: {
          index: 0,
        },
      },
      {
        path: "companyIntroduction",
        name: "companyIntroduction",
        component: () => import("../views/companyIntroduction.vue"),
        meta: {
          index: 1,
        },
      },
      // {
      //   path: "cooperativePartner",
      //   name: "cooperativePartner",
      //   component: () => import("../views/cooperativePartner.vue"),
      //   meta: {
      //     index: 2,
      //   },
      // },
      {
        path: "recruit",
        name: "recruit",
        component: () => import("../views/recruit.vue"),
        meta: {
          index: 2,
        },
      },
      {
        path: "contactUs",
        name: "contactUs",
        component: () => import("../views/contactUs.vue"),
        meta: {
          index: 3,
        },
      },
      // {
      //   path: "socialResponsibility",
      //   name: "socialResponsibility",
      //   component: () => import("../views/socialResponsibility.vue"),
      //   meta: {
      //     index: 5,
      //   },
      // },
      {
        path: "legalStatement",
        name: "legalStatement",
        component: () => import("../views/legalStatement.vue"),
        meta: {
          index: 4,
        },
      },
    ],
  },
  {
    path: "/newsPage",
    name: "newsPage",
    component: () => import("../views/newsPage.vue"),
  },
  {
    path: "/newsPageDetails",
    name: "newsPageDetails",
    component: () => import("../views/newsPageDetails.vue"),
  },
  {
    path: "/recruitDetails",
    name: "recruitDetails",
    component: () => import("../views/recruitDetails.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.afterEach((to, from) => {
  window.scrollTo(0, 0); //滚动条至于浏览器顶部
  pubsub.publish("routingJump", to);
});

export default router;
