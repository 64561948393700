/*
 * @Descripttion:
 * @version:
 * @Author: chenyu
 * @Date: 2024-06-22 15:50:19
 * @LastEditors: chenyu
 * @LastEditTime: 2024-08-06 15:57:24
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAwesomeSwiper from "vue-awesome-swiper";
// import AxiosPlugin from "./request/request";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper);
// Vue.use(AxiosPlugin); // 使用 Axios 插件
// Vue.use(Pagination); // 使用 Axios 插件
Vue.use(ElementUI); // 使用 ElementUI 插件

// import 'amfe-flexible';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
